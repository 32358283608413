import React from 'react';
import {connect} from 'react-redux';
import { i18n } from "../../config";
import jwt_decode from "jwt-decode";

import { CircularProgress, Button, TextField,
    Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';


const mapStateToPros = (state) => ({
    token: state.auth.token,
    contract: state.contracts.view_contract,
});

const styles = {
    infoFont: {
        fontSize: '1.6rem'
    }
};

class ContractElectronicNotificationDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: this.props.profile.data?.email,
            mobile: this.props.profile.data?.mobile,
        }
    }

    render() {
        const { submitted, sending, children } = this.props;
        const { email, mobile } = this.state;
        let actions;

        if (children) {
            actions = [
                <Button
                  label={ i18n.t('common:text.electronic_notification_close') }
                  color={'primary'}
                  variant={'text'}
                  onClick={this.props.handleClose}
                >
                    { i18n.t('common:text.electronic_notification_close') }
                </Button>,
            ];
        }
        else if (!submitted) {
            actions = [
                <Button
                    key={'cancel'}
                    color={'primary'}
                    variant={'text'}
                    onClick={this.props.handleClose}
                >
                    {i18n.t('common:text.electronic_notification_cancel')}
                </Button>,
                <Button
                    key={'request'}
                    color={'primary'}
                    variant={'text'}
                    disabled={sending}
                    onClick={() => this.props.handleSubmit(this.state.email, this.state.mobile)}
                >
                    {sending ? <CircularProgress size={14} /> : null}
                    {i18n.t('common:text.electronic_notification_request')}
                </Button>,
            ];
        }

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    disableBackdropClick={true}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {i18n.t('common:text.electronic_notification_title')}
                    </DialogTitle>
                    <DialogContent>
                        {this.props.children ||
                            <div>
                                <p style={styles.infoFont}>{i18n.t('common:text.electronic_notification_description')}</p>
                                <TextField
                                    label={i18n.t('common:text.contract_contact_data_email')}
                                    style={{ marginRight: 50 }}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    value={email}
                                />
                                <TextField
                                    label={i18n.t('common:text.contract_contact_data_phone')}
                                    onChange={(e) => this.setState({ mobile: e.target.value })}
                                    value={mobile}
                                />
                            </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect(mapStateToPros, null)(ContractElectronicNotificationDialog);
